import React, {useContext, useState, useEffect} from 'react';
import './style.scss';
import {Table, Button} from 'antd';
import {epochToDate, getUSDollar} from '../../libs/utility';
import {Context} from '../../store';
import {Link} from 'react-router-dom';
import {ReactComponent as TwLogo} from '../../assets/icons/tw-logo2.svg';

const DailyCandlesTable = ({data}) => {
    const {state} = useContext(Context);
    const [dailyList, setDailyList] = useState([]);

    console.log('DailyCandlesTable', data);

    useEffect(() => {
        let newDailyList = [];
        Object.keys(data).map(d => {
            let newItem = {
                symbol: d,
                [data[d][data[d].length - 10]?.localOpenTime.split(' ')[0]]: data[d][data[d].length - 10]?.change,
                [data[d][data[d].length - 9]?.localOpenTime.split(' ')[0]]: data[d][data[d].length - 9]?.change,
                [data[d][data[d].length - 8]?.localOpenTime.split(' ')[0]]: data[d][data[d].length - 8]?.change,
                [data[d][data[d].length - 7]?.localOpenTime.split(' ')[0]]: data[d][data[d].length - 7]?.change,
                [data[d][data[d].length - 6]?.localOpenTime.split(' ')[0]]: data[d][data[d].length - 6]?.change,
                [data[d][data[d].length - 5]?.localOpenTime.split(' ')[0]]: data[d][data[d].length - 5]?.change,
                [data[d][data[d].length - 4]?.localOpenTime.split(' ')[0]]: data[d][data[d].length - 4]?.change,
                [data[d][data[d].length - 3]?.localOpenTime.split(' ')[0]]: data[d][data[d].length - 3]?.change,
                [data[d][data[d].length - 2]?.localOpenTime.split(' ')[0]]: data[d][data[d].length - 2]?.change,
                [data[d][data[d].length - 1]?.localOpenTime.split(' ')[0]]: data[d][data[d].length - 1]?.change
            }
            newDailyList.push(newItem);
        });

        setDailyList(newDailyList);

        console.log('newDailyList', newDailyList);
    }, [data]);

    const columns = [
        {
            title: 'Symbol',
            dataIndex: 'symbol',
            key: 'symbol',
            render: (symbol, record) => symbolBuilder(symbol, record),
            sorter: (a, b) => ('' + a.symbol).localeCompare(b.symbol),
            width: 160
        },
        {
          title: data['BTCUSDT'][data['BTCUSDT'].length - 10].localOpenTime.split(' ')[0],
          dataIndex: data['BTCUSDT'][data['BTCUSDT'].length - 10].localOpenTime.split(' ')[0],
          key: '1',
          sorter: (a, b) => a[Object.keys(a)[1]] - b[Object.keys(a)[1]],
          render: (text, record) => {
              return {
                props: {
                  style: { background: parseFloat(text) > 0 ? "#f0ffef" : "#fff1f1" }
                },
                children: text ? `${text} %` : '-'
              };
          }
        },
        {
            title: data['BTCUSDT'][data['BTCUSDT'].length - 9].localOpenTime.split(' ')[0],
            dataIndex: data['BTCUSDT'][data['BTCUSDT'].length - 9].localOpenTime.split(' ')[0],
            key: '2',
            sorter: (a, b) => a[Object.keys(a)[2]] - b[Object.keys(a)[2]],
            render: (text, record) => {
                return {
                  props: {
                    style: { background: parseFloat(text) > 0 ? "#f0ffef" : "#fff1f1" }
                  },
                  children: text ? `${text} %` : '-'
                };
            }
        },
        {
            title: data['BTCUSDT'][data['BTCUSDT'].length - 8].localOpenTime.split(' ')[0],
            dataIndex: data['BTCUSDT'][data['BTCUSDT'].length - 8].localOpenTime.split(' ')[0],
            key: '3',
            sorter: (a, b) => a[Object.keys(a)[3]] - b[Object.keys(a)[3]],
            render: (text, record) => {
                return {
                  props: {
                    style: { background: parseFloat(text) > 0 ? "#f0ffef" : "#fff1f1" }
                  },
                  children: text ? `${text} %` : '-'
                };
            }
        },
        {
            title: data['BTCUSDT'][data['BTCUSDT'].length - 7].localOpenTime.split(' ')[0],
            dataIndex: data['BTCUSDT'][data['BTCUSDT'].length - 7].localOpenTime.split(' ')[0],
            key: '4',
            sorter: (a, b) => a[Object.keys(a)[4]] - b[Object.keys(a)[4]],
            render: (text, record) => {
                return {
                  props: {
                    style: { background: parseFloat(text) > 0 ? "#f0ffef" : "#fff1f1" }
                  },
                  children: text ? `${text} %` : '-'
                };
            }
        },
        {
            title: data['BTCUSDT'][data['BTCUSDT'].length - 6].localOpenTime.split(' ')[0],
            dataIndex: data['BTCUSDT'][data['BTCUSDT'].length - 6].localOpenTime.split(' ')[0],
            key: '5',
            sorter: (a, b) => a[Object.keys(a)[5]] - b[Object.keys(a)[5]],
            render: (text, record) => {
                return {
                  props: {
                    style: { background: parseFloat(text) > 0 ? "#f0ffef" : "#fff1f1" }
                  },
                  children: text ? `${text} %` : '-'
                };
            }
        },
        {
            title: data['BTCUSDT'][data['BTCUSDT'].length - 5].localOpenTime.split(' ')[0],
            dataIndex: data['BTCUSDT'][data['BTCUSDT'].length - 5].localOpenTime.split(' ')[0],
            key: '6',
            sorter: (a, b) => a[Object.keys(a)[6]] - b[Object.keys(a)[6]],
            render: (text, record) => {
                return {
                  props: {
                    style: { background: parseFloat(text) > 0 ? "#f0ffef" : "#fff1f1" }
                  },
                  children: text ? `${text} %` : '-'
                };
            }
        },
        {
            title: data['BTCUSDT'][data['BTCUSDT'].length - 4].localOpenTime.split(' ')[0],
            dataIndex: data['BTCUSDT'][data['BTCUSDT'].length - 4].localOpenTime.split(' ')[0],
            key: '7',
            sorter: (a, b) => a[Object.keys(a)[7]] - b[Object.keys(a)[7]],
            render: (text, record) => {
                return {
                  props: {
                    style: { background: parseFloat(text) > 0 ? "#f0ffef" : "#fff1f1" }
                  },
                  children: text ? `${text} %` : '-'
                };
            }
        },
        {
            title: data['BTCUSDT'][data['BTCUSDT'].length - 3].localOpenTime.split(' ')[0],
            dataIndex: data['BTCUSDT'][data['BTCUSDT'].length - 3].localOpenTime.split(' ')[0],
            key: '8',
            sorter: (a, b) => a[Object.keys(a)[8]] - b[Object.keys(a)[8]],
            render: (text, record) => {
                return {
                  props: {
                    style: { background: parseFloat(text) > 0 ? "#f0ffef" : "#fff1f1" }
                  },
                  children: text ? `${text} %` : '-'
                };
            }
        },
        {
            title: data['BTCUSDT'][data['BTCUSDT'].length - 2].localOpenTime.split(' ')[0],
            dataIndex: data['BTCUSDT'][data['BTCUSDT'].length - 2].localOpenTime.split(' ')[0],
            key: '9',
            sorter: (a, b) => a[Object.keys(a)[9]] - b[Object.keys(a)[9]],
            render: (text, record) => {
                return {
                  props: {
                    style: { background: parseFloat(text) > 0 ? "#f0ffef" : "#fff1f1" }
                  },
                  children: text ? `${text} %` : '-'
                };
            }
        },
        {
            title: data['BTCUSDT'][data['BTCUSDT'].length - 1].localOpenTime.split(' ')[0],
            dataIndex: data['BTCUSDT'][data['BTCUSDT'].length - 1].localOpenTime.split(' ')[0],
            key: '10',
            sorter: (a, b) => a[Object.keys(a)[10]] - b[Object.keys(a)[10]],
            render: (text, record) => {
                return {
                  props: {
                    style: { background: parseFloat(text) > 0 ? "#f0ffef" : "#fff1f1" }
                  },
                  children: text ? `${text} %` : '-'
                };
            }
        }
    ];

    const openUrl = (symbol) => {
        const link = state.marketType === "FUTURES" ? symbol.symbol + '.P' : symbol.symbol;
        window.open(`https://www.tradingview.com/chart/fu2csY4t/?symbol=${symbol.market.toUpperCase()}%3A${link}`, '_blank');
    }

    const symbolBuilder = (symbol, record) => {
        const sym = state.symbols.find(x => x.symbol === symbol);
        const imageName = sym.iconUrl; // state.marketType === "FUTURES" ? sym?.baseAsset?.toLowerCase() + '.svg' : sym.icon;
        return <div className="symbol">
            <Link to={`/currencies/details/${symbol.replace('USDT', '').toLowerCase()}`} state={{uniqId: sym.id}}>
                <img src={`${process.env.PUBLIC_URL}/images/crypto-logo/${imageName}`} alt="" />
                <span className="name"><strong>{sym.baseAsset}</strong></span>
            </Link>
            <Button type="primary" shape="circle" className='btn-black' style={{ marginLeft: 10}} icon={<span role="img" aria-label="tw" className="anticon anticon-history"><TwLogo /></span>} size={"small"} onClick={() => openUrl(sym)} />
        </div>
    }

    const rowClassNameBuilder = (record, index) => {
        if (state.analysisOptions.model === "rsiDivergence" || state.analysisOptions.model === "TC" || state.analysisOptions.model === "SD") {
            if(record.pnl > 0) {
                return "soft-green";
            }
            else if (record.pnl === 0) {
                return "soft-gray";
            }
            else {
                return "soft-red";
            }
        }
        else if(!state.enabledSimulator && (state.analysisOptions.model !== "depthHill" && state.analysisOptions.model !== "explosionLoose")) {
            return record.mostProfit > 1 ? "soft-green" : "soft-red";
        }
        else {
            if(record.pnl > 0.1) {
                return "soft-green";
            }
            else if (record.pnl >= 0 && record.pnl <= 0.1) {
                return "soft-gray";
            }
            else {
                return "soft-red";
            }
        }
    }

    return (
        <div className='all-daily-profit-loss-table'>
            <Table columns={columns} dataSource={dailyList} bordered pagination={false} />
        </div>
    );
};


export default DailyCandlesTable;