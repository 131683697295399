import React, { useEffect, useContext, useState, useCallback } from 'react';
import { binanceService } from '../../services';
import { Context } from '../../store';
import { AuthContext } from '../../contexts/AuthContext';
import {Row, Col, Radio, Button} from 'antd';
import AnalysisLoading from '../../components/AnalysisLoading';
import DailyCandlesTable from '../../components/DailyCandlesTable';
import CoinCurrentStatus from '../../components/CoinCurrentStatus';
import { marketType } from '../../libs/enums';
import { convertCandlestickData, convertDailyCandlestickData } from '../../libs/utility';
import './style.scss';

let allSymbolCandleStickList = {};

const DailyAnalysis = props => {
    const {state, dispatch} = useContext(Context);
    const { user } = useContext(AuthContext);
    const [isButtonVisible, setIsButtonVisible] = useState(true);
    const [symbolIndex, setSymbolIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const changeMarketType = (type) => {
        dispatch({type: "MARKET_TYPE", payload: type});
    }

    const startToFetchData = useCallback((index = 0) => {
        isButtonVisible && setIsButtonVisible(false);
        !isLoading && setIsLoading(true);

        if(state.marketType === 'FUTURES') {
            binanceService.getFuturesKlines(state.symbols[index].symbol, '1d', 20)
                .then(res => {
                    //res.splice(res.length - 1, 1);
                    allSymbolCandleStickList[state.symbols[index].symbol] = convertDailyCandlestickData(res); 

                    if(symbolIndex === state.symbols.length - 1) {
                        dispatch({type: "SAVE_DAILY_LIST", payload: allSymbolCandleStickList});
                        allSymbolCandleStickList = {};
                        setIsButtonVisible(true);
                        setSymbolIndex(0);
                        setIsLoading(false);
                    }
                    
                    setSymbolIndex(symbolIndex + 1);
                });                
        }
        else {
            binanceService.getSpotKlines(state.symbols[index].symbol, '1d', 20)
                .then(res => {
                    //res.splice(res.length - 1, 1);
                    allSymbolCandleStickList[state.symbols[index].symbol] = convertDailyCandlestickData(res); 
                    if(symbolIndex === state.symbols.length - 1) {
                        //console.log('state.supdemData', JSON.parse(JSON.stringify(state.supdemData)));
                        dispatch({type: "SAVE_DAILY_LIST", payload: allSymbolCandleStickList});
                        allSymbolCandleStickList = {};
                        setIsButtonVisible(true);
                        setSymbolIndex(0);
                        setIsLoading(false);
                    }
                    
                    setSymbolIndex(symbolIndex + 1);
                });
        }

    }, [dispatch, isButtonVisible, isLoading, symbolIndex, state.symbols, state.kLinesList]);

    useEffect(() => {
        if(symbolIndex > 0 && symbolIndex !== state.symbols.length) {
            startToFetchData(symbolIndex);
        }
    }, [symbolIndex, startToFetchData, state.symbols.length]);

    return (
        <>
            {isLoading && <AnalysisLoading current={symbolIndex} total={state.symbols.length} spin={Object.keys(state.kLinesList).length > 0 ? true : false} />}
            <div className='analysis container'>
                <Row gutter="16">
                    {Object.keys(state.kLinesList).length > 0 && 
                    <>
                        <Col span={12}>
                            <CoinCurrentStatus marketType={"FUTURES"} symbol={"BTCUSDT"} />
                        </Col>
                        <Col span={12}>
                            <CoinCurrentStatus marketType={"FUTURES"} symbol={"ETHUSDT"} />
                        </Col>
                    </>
                    }
                    <Col span={24}>
                        {(isButtonVisible && !state.showDealScreen) && 
                            <div className="ai-options">
                                <Row justify="center">
                                    {Object.keys(state.dailyKlinesList).length === 0 && 
                                        <>
                                            <Col span={24} className="select-mt">
                                                <p>Select Market Type:</p>
                                                <Radio.Group options={marketType} onChange={({target: {value}}) => changeMarketType(value)} value={state.marketType} optionType="button" buttonStyle="solid" />
                                            </Col>
                                        </>
                                    }
                                    <Col span={24}>
                                        <Button type="primary" size='large' block className='btn-analysis' onClick={() => startToFetchData(0)}>Start to Analysis</Button>
                                    </Col>
                                </Row>  
                            </div>
                        }                

                        {state.showDealScreen && 
                            <div className="deals">
                                <DailyCandlesTable data={state.dailyKlinesList} />
                            </div> 
                        } 
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default React.memo(DailyAnalysis);
