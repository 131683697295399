import { createContext, useState } from "react";
import {auth} from '../firebase-config';
import {signInWithPopup, GoogleAuthProvider, signOut} from 'firebase/auth';
import { firebaseService } from '../services/firebaseService';

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [user, setUser] = useState({});
    const [privateKey, setPrivateKey] = useState("");

    const googleLogin = async () => {
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider)
                .then(async result => {
                    // result.user.uid = '';
                    //setUser({...result.user, uid: 'EaiCK2ViZkTWTJnLl4E3qFFZ0oy2'});
                    setUser(result.user);
                    localStorage.setItem('token', result.user.accessToken);
                    localStorage.setItem('user', JSON.stringify(result.user));
                    console.log(result.user);

                    let existUser = await firebaseService.getUser(result.user.uid);
                    console.log('exist user', existUser);
                    if(!existUser) {
                        await firebaseService.addUser(result.user);
                    }
                })
                .catch(err => {
                    //console.log(err);
                })
    }

    const googleLogout = async () => {
        await signOut(auth);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setUser({});
    }

    const checkUser = () => {
        auth.currentUser.getIdToken(true).then(function(idToken) {
            console.log('user id token', idToken);
            // Send token to your backend via HTTPS
            // ...
          }).catch(function(error) {
            // Handle error
          });
    }

    return (
        <AuthContext.Provider value={{user, privateKey, googleLogin, googleLogout, checkUser, setPrivateKey}}>
            {children}
        </AuthContext.Provider>
    );
}