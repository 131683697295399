import React, { useEffect, useContext } from 'react';
import {Link, useLocation} from 'react-router-dom';
import { Context } from '../../store';
import { CalculatorOutlined } from '@ant-design/icons';
import './style.scss';
import {analysisModel, allAccess} from '../../libs/enums';
import {Button} from 'antd';
import { AuthContext } from '../../contexts/AuthContext';


const nav = [
    {
        name: "Home",
        path: '/',
        access: 'general'
    },
    {
        name: "Analysis",
        path: '/analysis',
        access: 'required'
    },
    {
        name: "Daily Analysis",
        path: '/daily-analysis',
        access: 'required'
    },
    {
        name: "Currencies",
        path: "/currencies",
        access: 'required'
    },
    {
        name: "All PNL",
        path: "/all-pnl",
        access: 'required',
    },
    {
        name: "Spot Wallets",
        path: "/wallet",
        access: 'general'
    },/*
    {
        name: "Cumulative",
        path: "/cumulative"
    },*/
    {
        name: "Accounts",
        path: "/accounts",
        access: 'general'
    },    
];

const Navigation = ({onChangeModel}) => {
    const location = useLocation();
    const {state, dispatch} = useContext(Context);
    //console.log(location);
    const { user } = useContext(AuthContext);

    const openCalculator = () => {
        dispatch({type: "SHOW_CALCULATOR", payload: true});
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const navigation = nav.map((navItem, i) => {
        let className = location.pathname === navItem.path ? "active": "";
        if (navItem.access === "required" && !allAccess.includes(user.uid)) {
            return null;
        }
        return <li key={i} className={className}>
        <Link to={navItem.path}>{navItem.name}</Link>
      </li>
    });

    const getAnalysisModel = () => {
        const model = analysisModel.find(m => m.value === state.analysisOptions.model);
        return model.label;
    }

    const lastUpdate = () => {
        let lastUpdate = new Date(state.supdemData.lastUpdate);
        return lastUpdate.toLocaleString();
    }

    return (
        <div className='navigation'>
        <ul>
                {navigation}
                {Object.keys(state.kLinesList).length > 0 && state.enabledSimulator &&
                    <li key={nav.length} className={location.pathname === '/simulator' ? "active": ""}>
                        <Link to={'/simulator'}>{'Simulator'}</Link>
                    </li>
                }            
        </ul>
        {allAccess.includes(user.uid) && 
            <>
                <div className='calc-icon'>
                    <CalculatorOutlined style={{ fontSize: '30px', color: '#e5ac17' }} onClick={openCalculator}/>
                </div>
                <div className="current-model">
                    <p>Current Analysis Model: <br /><strong>{getAnalysisModel()}</strong></p>
                    {/*Object.keys(state.kLinesList).length > 0 && !state.enabledSimulator && <Button type="link" onClick={onChangeModel}>Change</Button>*/}
                </div>
                <div className="last-update">
                    <p>Analysis Market Type: <br /><strong>{state.marketType}</strong></p>
                </div> 
                <div className="last-update">
                    <p>Last Data Update: <br /><strong>{lastUpdate()}</strong></p>
                    {/*Object.keys(state.kLinesList).length > 0 && !state.enabledSimulator && <Button type="link" onClick={onChangeModel}>Change</Button>*/}
                </div> 
            </>
        }  
      </div>
    );
};

export default Navigation;